// Import React Library
import React from 'react';

// Import React Icons
import { FiX } from "react-icons/fi";

// Import Alerts
import Alert from '@mui/material/Alert';

// CreateProductForm Component
const CreateProductForm = ({setShowForm, successUpload, errorUpload, handleSubmit, newProduct, setNewProduct, errorMessage, categories, handleImageChange, previewImage}) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Crear producto</h2>
                    <button
                        onClick={() => setShowForm(false)}
                        className="p-2 hover:bg-gray-100 rounded-full"
                    >
                        <FiX />
                    </button>
                </div>

                {
                    successUpload && <Alert variant="outlined" severity="success" className="p-2 my-2"> El producto se ha cargado correctamente.</Alert>
                }

                {
                    errorUpload && <Alert variant="outlined" severity="error" className="p-2 my-2">{errorMessage}</Alert>
                }
                
                <form onSubmit={handleSubmit}>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium mb-1">Nombre</label>
                            <input
                                type="text"
                                required
                                className="w-full p-2 border rounded-lg"
                                value={newProduct.name}
                                onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-1">Descripcion</label>
                            <textarea
                                required
                                className="w-full p-2 border rounded-lg"
                                value={newProduct.description}
                                onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-1">Precio</label>
                            <input
                                type="number"
                                required
                                step="0.01"
                                className="w-full p-2 border rounded-lg"
                                value={newProduct.price}
                                onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-1">Stock</label>
                            <input
                                type="number"
                                required
                                min="0"
                                className="w-full p-2 border rounded-lg"
                                value={newProduct.stock}
                                onChange={(e) => setNewProduct({ ...newProduct, stock: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-1">Categoria</label>
                            <select
                                required
                                className="w-full p-2 border rounded-lg"
                                value={newProduct.category}
                                onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })}
                            >
                                <option value="">Select a category</option>
                                {categories.map((category) => (
                                <option key={category} value={category}>
                                    {category}
                                </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-1">Imagen del producto</label>
                            <input
                                required
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                className="w-full p-2 border rounded-lg"
                                name="file"
                            />
                            {previewImage && (
                                <img
                                src={previewImage}
                                alt="Preview"
                                className="mt-2 w-full h-48 object-cover rounded-lg"
                                />
                            )}
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-gray-600 text-white py-2 rounded-lg hover:bg-gray-500 transition-colors"
                        >
                            Crear Producto
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

// Exprt CreateProductForm Component
export default CreateProductForm;