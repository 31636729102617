// Import Styles
import './sass/index.scss';
import './styles/index.css';

// Import Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

// Import Components
import Navbar from './components/Navbar';
import Cover from './components/Cover';
import Banner from './components/Banner';
import MakeYourModule from './components/MakeYourModule';
import News from './components/News';
import Advantages from './components/Advantages';
import Marquee from './components/Marquee';
import AboutUs from './components/AboutUs';
import Location from './components/Location';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Faq from './components/Faq';
import WhatsappButton from './components/WhatsappButton';
import Footer from './components/Footer';

// Google ADS Tracking
import ReactGA from "react-ga4";

// Import Atropos
import 'atropos/css'

// App Component
function App() {

  ReactGA.initialize("G-1ER3JSR5KS"); // Reemplaza con tu Tracking ID

  ReactGA.send("pageview");

  return (
    <div className="App">
      <Navbar status={true}/>
      <Cover section='home' coverImg={true}/>
      <News />
      <Advantages />
      <AboutUs />
      <WhatsappButton />
      <Location />
      <Projects />
      <MakeYourModule />
      <Faq />
      <Banner />
      <Contact />
      <Marquee />
      <Footer/>
    </div>
  );
}

// Export App Component
export default App;
