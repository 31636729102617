// Import Firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEayJiMtrFEGGPPHEmS6m6mMz1dn2jT8A",
  authDomain: "prehousewebsite-9bd6f.firebaseapp.com",
  projectId: "prehousewebsite-9bd6f",
  storageBucket: "prehousewebsite-9bd6f.appspot.com",
  messagingSenderId: "221330723281",
  appId: "1:221330723281:web:df09bb5340de5b89c6d0f0",
  measurementId: "G-TVHK4F67Z2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);