// Import React Library
import React, { useState, useEffect } from 'react';

// Import Images
import logoPreHouse from '../assets/images/logo-pre-house-2.png'

// Import Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faPersonDigging } from '@fortawesome/free-solid-svg-icons';
import SensorWindowIcon from '@mui/icons-material/SensorWindow';
import VillaIcon from '@mui/icons-material/Villa';
import CabinIcon from '@mui/icons-material/Cabin';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import FitbitIcon from '@mui/icons-material/Fitbit';
import LogoutIcon from '@mui/icons-material/Logout';

// Import Components
import NavbarMobile from './NavbarMobile';

// Import Link react-router
import { Link } from 'react-router-dom';

// Navbar Component
const Navbar = ({status , isDashboardNav}) => {

    // Handle responsive navbar
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean the event when unmount component
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {windowWidth < 1492 ? (
               <NavbarMobile  status={status}/>
            ) : (
                <nav className={isDashboardNav && 'dashboard-nav'}>
                    <Link to='/'>
                        <img src={logoPreHouse} alt='Logo PreHouse'/>
                    </Link>

                    {
                        isDashboardNav ? 
                        <ul>
                            <Link to='/windows'>
                                <li>Cerrar Sesion  <LogoutIcon className='general-icon'/></li>
                            </Link>
                        </ul> :

                        <ul>
                            <Link to='/windows'>
                                <li>Aberturas  <SensorWindowIcon className='general-icon'/></li>
                            </Link>
                            <Link to='/constructions'>
                                <li>Construcciónes <FontAwesomeIcon icon={faPersonDigging} className='general-icon'/></li>
                            </Link>
                            <Link to='/galvanized-iron'>
                                <li>Hierros  <CabinIcon className='general-icon'/></li>
                            </Link>
                            <Link to='/isopaneles'>
                                <li>Isopaneles <VillaIcon className='general-icon'/></li>
                            </Link>
                            <Link to='/floor'>
                                <li>Pisos <FitbitIcon icon={faHouse} className='general-icon'/></li>
                            </Link>
                            <Link to='/pre-house-home'>
                                <li>PreHouse Home <FontAwesomeIcon icon={faHouse} className='general-icon'/></li>
                            </Link>
                            <Link to='/coatings'>
                                <li>Revestimientos <HolidayVillageIcon icon={faHouse} className='general-icon'/></li>
                            </Link>

                            { status ? <li className='contact-link'><a href='#contact-form'>Contacto</a></li> : ''}
                        </ul>
                    }
                </nav>
            )}
        </>
    );
};

// Export Navbar Component
export default Navbar;