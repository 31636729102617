// Import React Library
import React from 'react';

// DeleteConfirmation Component
const DeleteConfirmation = ({productToDelete , setShowDeleteModal, confirmDelete}) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                <div className="bg-white rounded-lg p-6 w-full max-w-sm">
                    <h2 className="text-xl font-bold mb-4">Confirmar eliminacion</h2>
                    <p className="mb-4">Estas seguro que desea eliminar el producto <span className='font-bold'>{productToDelete}</span>?</p>
                    <div className="flex justify-end space-x-2">
                        <button
                            onClick={() => setShowDeleteModal(false)}
                            className="px-4 py-2 border rounded-lg hover:bg-gray-100"
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={confirmDelete}
                            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                        >
                            Eliminar
                        </button>
                    </div>
                </div>
            </div>
    );
};

// Export Delete Confirmation Message.
export default DeleteConfirmation;